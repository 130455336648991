<template>
  <div class="container">
    <Header />
    <div class="main">
      <div class="success-message">
        <van-icon name="passed" size="60" color="#005DA9" />{{ $t('bookingSuccess') }}
      </div>

      <div style="text-align: center; margin-top: 20px; color: #515a6e;">
        {{ $t('successMessage') }}
      </div>
      <div style="text-align: center">
        <el-button size="large" style="width: 300px; background-color: #005DA9; height: 50px; color: white; margin-top: 100px;" @click="goMyBooking">{{ $t('bookingList') }}</el-button>
      </div>

      <div style="flex: 1;"></div>
      <Footer />
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header'
import { formatNumber } from '@/filters'
import { Notify } from 'vant'

export default {
  name: 'BookingSuccess',
  components: {
    Header
  },
  data () {
    return {

    }
  },
  computed: {
  },
  methods: {
    formatNumber,
    afterRead (file) {
      // 此时可以自行将文件上传至服务器
      console.log(file)
    },
    onOversize (file) {
      Notify(this.$t('file size limit is {limit} KB', { limit: 500 }))
    },
    goMyBooking () {
      this.$router.push({
        name: 'ReservationList'
      })
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  height: 100vh;
}

.main {
  padding-top: 120px;
  max-width: 1200px;
  margin: 0 auto;
}

.success-message {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 1.3rem;
  padding: 0 3rem;
}

.footer {
  margin: 0 1em;
}

</style>
